<template>
    <MainLayout>
        <div class="row confirm">
            <div class="confirm__row col-12 col-lg-6 offset-lg-3 text-center">
                <h2>Please enter Code Sent To Your Email</h2>
                <h6>An email has been sent to {{email}}</h6>
            </div>
            <div class="confirm__row col-12 col-lg-4 offset-lg-4">
                <input v-model="code" type="text" class="form-control mt-3">
                <div class="btn-c2 mb-4 mt-3 ms-auto me-auto" @click="confirm">Confirm</div>
            </div>
        </div>
    </MainLayout>
</template>

<script>
import MainLayout from '../layouts/MainLayout';
import { errorMessage } from '../services/messages';
import { mapMutations } from 'vuex';

export default {
    name: 'ConfirmEmail',
    components: {
        MainLayout,
    },
    data() {
        return {
            code: '',
            email: null,
            pass: null,
        };
    },
    mounted() {
        this.email = localStorage.getItem('strobeart_confirm_email');
        this.pass = localStorage.getItem('strobeart_confirm_email_pass');
    },
    methods: {
        ...mapMutations([
            'showLoader',
            'hideLoader',
            'setUser',
            'setLogged',
        ]),
        async confirm() {
            if (this.email && this.pass) {
                try {
                    this.showLoader();
                    const response = await this.$http.post(`${this.$http.apiUrl()}login`, {
                        'email': this.email,
                        'password': this.pass,
                        'code': this.code,
                    });
                    if (response && response.data && response.data.data && response.data.data.token) {
                        const token = response.data.data.token;
                        localStorage.setItem('strobeart_jwt', token);
                        this.setLogged(true);
                        if(response.data.data.user) {
                            localStorage.setItem('strobeart_user', JSON.stringify(response.data.data.user));
                            this.setUser(response?.data?.data?.user || {});
                        }
                    }
                    this.$router.push({to: '/', name: "Home"}).then();
                } catch (e) {
                    const message = e?.response?.data?.error?.message || 'ERROR';
                    errorMessage(message);
                }
                this.hideLoader();
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.confirm {
    padding: 5px;
    @media only screen and (max-width: 992px) {
        margin-bottom: 260px;
        &__row {
            padding: 15px 15px 15px 20px;
            text-align: center;
        }
    }
    @media only screen and (max-width: 599px) {
        margin-bottom: 110px;
    }
}
</style>
